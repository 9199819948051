import React, { useState, useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { SwitchTransition, CSSTransition } from "react-transition-group";
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import CardArticle from '../components/CardArticle'
import Pagination from '../components/Pagination'
import SplitText from '../components/SplitText'
import { getArticlesByCategories, getAllArticles } from '../services/prismic';
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'

const pageSize = 6;

const INTERNAL_CATEGORY = 'In The Media';

const Blog = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation;
	const prismicFooter = data.prismicFooter;
	const prismicBlog = data.prismicBlog.data;
	const categories = prismicBlog.categories.map(c => c.category);

	const [selectedCategories, setSelectedCategories] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPage, setMaxPage] = useState(1);
	const [articles, setArticles] = useState([]);

	useEffect(() => {
		getArticles();
	}, [])

	const getArticles = (categories = [], page = 1) => {
		setCurrentPage(page);
		setSelectedCategories(categories)

		if (categories.length > 0) {
			getArticlesByCategories(categories, pageSize, page).then(res => {
				handleArticlesReceived(res)
			});
		} else {
			getAllArticles(pageSize, page).then(res => {
				handleArticlesReceived(res)
			});
		}
	}

	const handleArticlesReceived = res => {
		const formattedRes = formatRes(res.results)
		setArticles(formattedRes);
		setMaxPage(res.total_pages);
	}

	const formatRes = res => {
		return res.map(article => {
			let firstPublicationDate = new Date(article.first_publication_date);
			firstPublicationDate = firstPublicationDate.toLocaleDateString('en-US');
			return {
				uid: article.uid,
				firstPublicationDate: firstPublicationDate,
				...article.data
			}
		})
	}

	const onCategoryClick = e => {
		let category = e.target.getAttribute('data-category');
		const categoryIndex = selectedCategories.indexOf(category);
		let newSelectedCategories = [...selectedCategories];
		if (categoryIndex === -1) {
			newSelectedCategories.push(category)
			e.target.classList.add('Blog__CategoriesButton--selected');
		}
		else {
			newSelectedCategories.splice(categoryIndex, 1);
			e.target.classList.remove('Blog__CategoriesButton--selected');
		}
		getArticles(newSelectedCategories, 1);
	}

	const onPaginationPageSelected = selectedPage => {
		getArticles(selectedCategories, selectedPage);
	}

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onCategoryClick(e);
		}
	}

	const pageTitle = "Terracotta Exteriors Blog | Shildan Group";
	const metaDescription = "Stay up to date with the latest industry news and learn more about Shildan teracotta facades, company updates, new materials, latest designs, and upcoming projects.";
	const metaKeywords = "terracotta facades, Shildan Terracotta";

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="blog" navigation={prismicNavigation} footer={prismicFooter} />
			<section className="Blog">
				<h1
					className="Blog__Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						{prismicBlog.banner_title.text}
					</SplitText>
				</h1>
				<div className="Blog__Container">
					<div className="Blog__Categories">
						<h4 className="Blog__CategoriesTitle">Categories: </h4>
						<ul
							className="Blog__CategoriesList"
							data-is-on-screen
						>
							{categories.map((category, index) => (
								<li
									className="Blog__CategoriesItem"
									key={`blog-category-item-${index}`}
								>
									<div
										className="Blog__CategoriesButton"
										onClick={onCategoryClick}
										onKeyDown={handleOnKeyDown}
										role="button"
										tabIndex={0}
										data-category={category}
									>
										{category}
									</div>
								</li>
							))}
						</ul>
					</div>
					<div className="Blog__Articles">
						<SwitchTransition mode="out-in">
							<CSSTransition
								key={articles}
								timeout={200}
							>
								<div className="Blog__ArticlesContainer">
									{articles.map((article, index) => {
										return (
											<CardArticle
												type={index === 0 ? 'big' : 'small'}
												article={article}
												key={`article-index-${index}`}
											/>
										);
									})}
								</div>
							</CSSTransition>
						</SwitchTransition>
					</div>
					<Pagination
						current={currentPage}
						max={maxPage}
						onPageSelected={onPaginationPageSelected}
					/>
				</div>
			</section>

			<Footer footer={prismicFooter.data} />
    </div>
  )
}

export const query = graphql`
	query Blog {
		prismicBlog {
			data {
				banner_title {
					text
				}
				categories {
					category
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default Blog